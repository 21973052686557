export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 35,
  w: 96,
  h: 96,
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [-180]
            },
            { t: 26.2724609375, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [48, 48, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0, 0, 0], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [0, 0, 100]
            },
            { t: 19.318359375, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 12.363,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-1.765, -1.765],
                          [-4.802, 3.191],
                          [-1.765, 1.765],
                          [3.214, 4.554],
                          [1.765, 1.765],
                          [4.931, -3.318],
                          [1.765, -1.765],
                          [-3.173, -4.345]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 34,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-1.765, -1.765],
                          [-6, 0.353],
                          [-1.765, 1.765],
                          [0, 6],
                          [1.765, 1.765],
                          [6, 0.353],
                          [1.765, -1.765],
                          [0, -6]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.207843139768, 0.32549020648, 0.858823537827, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.207843139768, 0.32549020648, 0.858823537827, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
